exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-n-colourings-index-js": () => import("./../../../src/pages/n-colourings/index.js" /* webpackChunkName: "component---src-pages-n-colourings-index-js" */),
  "component---src-pages-tilegraphs-index-js": () => import("./../../../src/pages/tilegraphs/index.js" /* webpackChunkName: "component---src-pages-tilegraphs-index-js" */),
  "component---src-pages-tilegraphs-strapi-tilegraph-slug-js": () => import("./../../../src/pages/tilegraphs/{strapiTilegraph.slug}.js" /* webpackChunkName: "component---src-pages-tilegraphs-strapi-tilegraph-slug-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-colour-coding-js": () => import("./../../../src/templates/colour-coding.js" /* webpackChunkName: "component---src-templates-colour-coding-js" */),
  "component---src-templates-glueing-looping-js-content-file-path-src-markdown-content-glueing-looping-mdx": () => import("./../../../src/templates/glueing-looping.js?__contentFilePath=/Users/rasmus/Documents/Projects/transpositional_topology_site/gatsby_transpositional/src/markdown/content/glueing-looping.mdx" /* webpackChunkName: "component---src-templates-glueing-looping-js-content-file-path-src-markdown-content-glueing-looping-mdx" */),
  "component---src-templates-gyres-js-content-file-path-src-markdown-content-gyres-mdx": () => import("./../../../src/templates/gyres.js?__contentFilePath=/Users/rasmus/Documents/Projects/transpositional_topology_site/gatsby_transpositional/src/markdown/content/gyres.mdx" /* webpackChunkName: "component---src-templates-gyres-js-content-file-path-src-markdown-content-gyres-mdx" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-index-signatures-js": () => import("./../../../src/templates/index-signatures.js" /* webpackChunkName: "component---src-templates-index-signatures-js" */),
  "component---src-templates-n-colourings-js": () => import("./../../../src/templates/n-colourings.js" /* webpackChunkName: "component---src-templates-n-colourings-js" */),
  "component---src-templates-post-js-content-file-path-src-markdown-posts-first-post-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/rasmus/Documents/Projects/transpositional_topology_site/gatsby_transpositional/src/markdown/posts/first-post.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-markdown-posts-first-post-mdx" */),
  "component---src-templates-post-js-content-file-path-src-markdown-posts-irregular-tilings-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/rasmus/Documents/Projects/transpositional_topology_site/gatsby_transpositional/src/markdown/posts/irregular-tilings.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-markdown-posts-irregular-tilings-mdx" */),
  "component---src-templates-post-js-content-file-path-src-markdown-posts-mumeta-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/rasmus/Documents/Projects/transpositional_topology_site/gatsby_transpositional/src/markdown/posts/mumeta.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-markdown-posts-mumeta-mdx" */),
  "component---src-templates-post-js-content-file-path-src-markdown-posts-petrie-paths-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/rasmus/Documents/Projects/transpositional_topology_site/gatsby_transpositional/src/markdown/posts/petrie_paths.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-markdown-posts-petrie-paths-mdx" */)
}

